<template>
  <div id="Main">
    <router-view style="padding-bottom:0.59rem" />
    <footer>
      <div class="active"   @click="changePath('/Home')">
        <img v-if="path == '/Home'" src="../assets/image/main/home_check.png"/>
        <img v-else src="../assets/image/main/home.png"/>首页
      </div>
      <div class="active"  @click="changePath('/Connections')"  >
        <img v-if="path == '/FindOut' || path == '/Connections' || path == '/CompanyList' || path == '/ActivityLine'" style="height:0.25rem;margin-bottom:0" src="../assets/image/main/circle_check.png"/>
        <img v-else style="height:0.25rem;margin-bottom:0" src="../assets/image/main/circle.png"/>社群
      </div>
      <div class="active" @click="changePath('/StarMix')" >
        <img v-if="path == '/Information' || path == '/ActivityCourse' || path == '/Inspiration' || path == '/StarMix'"  src="../assets/image/main/round_check.png"/>
        <img v-else style="height:0.22rem;margin-bottom:0.03rem" src="../assets/image/main/round.png"/>星球  
      </div>
      <div class="active"   @click="changePath('/Message')">
        <img v-if="path == '/Message'" style="height:0.22rem;margin-bottom:0.03rem" src="../assets/image/main/message_check.png"/>
        <img v-else style="height:0.22rem;margin-bottom:0.03rem" src="../assets/image/main/message.png"/>消息
      </div>
      <div class="active"  @click="changePath('/Personal')">
        <img v-if="path == '/Personal'" style="height:0.22rem;margin-bottom:0.03rem" src="../assets/image/main/mine_check.png"/>
        <img v-else style="height:0.22rem;margin-bottom:0.03rem" src="../assets/image/main/mine.png"/>我的
      </div>
    </footer>
  </div>
</template>
<script>
export default {
  name: 'Main',
   data(){
    return{
      path:false
    }
  },
  mounted(){
    console.log(this.$route.path)

    this.path = this.$route.path
  },
  methods:{
    changePath(path){
      console.log(path)
      this.path = path
      this.$router.push(path)
    },
    first(){
      console.log(this.$route)
      this.path = this.$route.path
    }
  },
  watch: {
    '$route': 'first'
  }

}
</script>
<style lang="less" scoped>
footer{
  padding: 0.08rem 0.15rem;
  display: flex;
  justify-content: space-around;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.35);
  background: #16171D;
  border-top: 1px solid #32353F;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 0.6rem;
  box-sizing: border-box;
  z-index: 3;
  max-width: 750px;
  .active{
    color: #ECEDF7;
  }
  >div{
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #989AB1;
    justify-content: space-between;
    font-size: 0.12rem;
    >img{
      height:0.2rem;
      margin-bottom: 0.05rem;
    }
  }
}
</style>